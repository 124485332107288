import { IRenderer, IMG_DUMP_ATTR } from './renderer';
import schwellwertFs from './schwellwert.fs';
import imgDumpVs from './img.dump.vs';
import * as REGL from "regl";

export class RendererSchwellwert implements IRenderer {

  dumpTexture;

    init(opt) {   
        this.dumpTexture = opt.regl({
          frag: schwellwertFs,    
          vert: imgDumpVs,
          attributes: {
            a_pos: IMG_DUMP_ATTR,
          },
          count: 3,
          uniforms: {
            u_tex: opt.sourceTexture
          },
        });
      }

      render( context, sourceTexture) {
        this.dumpTexture({
            u_tex: sourceTexture
        });
      }

      destroy() {
      }
}