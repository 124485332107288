import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';

import { IRenderer} from '../renderer/renderer';
import { RendererSchwellwert} from '../renderer/schwellwert.renderer';
import { FindPaperRenderer} from '../renderer/find.paper';
import * as REGL from 'regl';

@Component({
  selector: 'app-regl',
  templateUrl: './regl.component.html',
  styleUrls: ['./regl.component.scss']
})
export class ReglComponent implements OnInit {

  @ViewChild('video') videoRef: ElementRef;
  @ViewChild('canvas') canvasRef: ElementRef;

  inLoop = false;
  tick;

  videoTexture;
  regl: REGL.Regl;
  stream: MediaStream;
  renderer: IRenderer;

  ngOnInit(): void {
    this.renderer = new RendererSchwellwert();
    this.renderer = new FindPaperRenderer();
  }

  ngAfterViewInit(): void {
    this.initVideoStream();
  }

  initRegl() {
    let canvas = this.canvasRef.nativeElement;
    const ratio = 1;//window.devicePixelRatio;
    canvas.width = canvas.clientWidth * ratio;
    canvas.height = canvas.clientHeight * ratio;

    const regl = this.regl = REGL({
      canvas: canvas,
    });

    this.videoTexture = regl.texture( this.videoRef.nativeElement);

    const environment = "environment" === this.stream.getVideoTracks()[0].getSettings().facingMode;
    this.renderer.init({
      regl: regl, 
      sourceTexture: this.nextVideoFrameTexture(),
      facingEnvironment: environment,
    });
  }

  nextVideoFrameTexture() {
    const vtw = this.videoTexture.width;
    const vts = this.stream.getVideoTracks()[0].getSettings();
    // passiert, wenn Smartphone oder Tablet gedreht wird.
    if( vtw != vts.width) {
      console.log(`gedreht size( ${vts.width}, ${vts.height})`);
      this.videoTexture.resize( vts.width, vts.height);
    }
    return this.videoTexture.subimage(this.videoRef.nativeElement);
  }

  render(context) {
    this.renderer.render(context, this.nextVideoFrameTexture());
  }

  onVideoPlaying() {
    try {
      this.initRegl();
      this.startAnimation();
    } catch (error) {
      console.error(error);
    }
  }

  dump() {
    if( this.renderer.dump) {
      this.renderer.dump();
    }
  }

  initVideoStream() {
    const video = this.videoRef.nativeElement;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const constraints = {
        video: {
          width: 1280, 
          height: 720,
          frameRate: 10, 
          facingMode: {
            ideal: 'environment'
          }
        }
      };
      navigator.mediaDevices.getUserMedia(constraints).then(stream => {
        this.stream = stream;
        video.srcObject = stream;
        video.play();
      }).catch(
        error => console.error(error)
      );
    } else {
      console.log('Keine Kamera verfügbar oder Erlaubnis sie zu verwende wurde nicht erteilt.');
    }
  }

  stopAnimation() {
    this.inLoop = false;
    this.tick.cancel();
    console.log("animation stopped");
  }

  startAnimation() {
    console.log("start animation");
    this.inLoop = true;
    this.tick = this.regl.frame(context => this.render(context));
  }
}
