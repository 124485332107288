import { IRenderer, IMG_DUMP_ATTR, IRendererOptions } from './renderer';
import pass1Fs from './find.paper.pass1.fs';
import imgDumpFs from './img.dump.fs';
import imgDumpVs from './img.dump.vs';
import * as REGL from 'regl';

export class FindPaperRenderer implements IRenderer {
    
    pass1;
    pass2;
    regl;
    pass1Result;
    framebufferPass1: REGL.Framebuffer2D;
    
    init(opt: IRendererOptions) {
        const regl = this.regl = opt.regl;
        const stw = opt.sourceTexture.width;
        const sth = opt.sourceTexture.height;

        const xRegions = Math.trunc(stw / 16 / 2);
        const yRegions = Math.trunc(sth / 16 / 2);

        const xStep = 1./stw;
        const yStep = 1./sth;

        const xOffset = (stw - (xRegions * 2 - 1) * 16) / 2 * xStep;
        const yOffset = (sth - (yRegions * 2 - 1) * 16) / 2 * yStep;

        console.log(`xOffset ${xOffset} in Pixel ${xOffset / xStep}`)

        this.framebufferPass1 = regl.framebuffer({
            width: xRegions, 
            height: yRegions, 
            depthStencil: false,
        });

        this.pass1 = regl({
            framebuffer: this.framebufferPass1,
            frag: pass1Fs,    
            vert: imgDumpVs,
            attributes: {
              a_pos: IMG_DUMP_ATTR,
            },
            count: 3,
            uniforms: {
              u_flip: [false, false],
              u_tex: opt.sourceTexture,
              u_step: [ xStep, yStep],
              u_offset: [xOffset, yOffset]
            },
          });

          this.pass2 = regl({
            frag: imgDumpFs,
            vert: imgDumpVs,
            attributes: {
              a_pos: IMG_DUMP_ATTR,
            },
            count: 3,
            uniforms: {
              u_flip: [!opt.facingEnvironment, true],
              u_tex: this.framebufferPass1,
            }
          })
      }

    render(context, sourceTexture: REGL.Texture2D) {
      this.pass1();
      this.pass2();
    }

    destroy() {
      this.framebufferPass1.destroy();
    }

    dump() {
      console.log(JSON.stringify(this.pass1Result));
    }
}