import * as REGL from "regl";

export const IMG_DUMP_ATTR = [-2, 0, 0, -2, 2, 2];

export interface IRenderer {
    init(options: IRendererOptions);
    render(context, sourceTexture: REGL.Texture2D);
    destroy();
    dump?(): void;
}

export interface IRendererOptions {
    regl: REGL.Regl;
    sourceTexture: REGL.Texture2D;
    facingEnvironment: boolean;
}
